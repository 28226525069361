<script setup lang="ts">
/**
 * State
 */
const { t } = useI18n();
const route = useRoute();
const storeApp = useApp();
const router = useRouter();
const storeUser = useUser();
const open: Ref<boolean> = ref(false);
const scanOpen: Ref<boolean> = ref(
  !!route.name?.toString()?.startsWith('scan.'),
);
const quizOpen: Ref<boolean> = ref(
  !!route.name?.toString()?.startsWith('quiz.'),
);

/**
 * Methods
 */
const onboarding = (): void => {
  open.value = false;

  storeUser
    .setOnboarded('scan', false)
    .then(() =>
      setTimeout(() =>
        router.push({ name: 'scan.onboarding', params: { id: 1 } }),
      ),
    );
};
</script>

<template>
  <nav>
    <!-- Trigger -->
    <button @click="open = !open">
      <img src="/images/icons/hamburger.svg" class="h-[31px] w-[46px]" />
    </button>

    <!-- Menu -->
    <HeadlessTransitionRoot as="template" :show="open">
      <HeadlessDialog as="div" class="relative z-10" @close="open = false">
        <HeadlessTransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </HeadlessTransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div
              class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-32"
            >
              <HeadlessTransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from="translate-x-0"
                leave-to="translate-x-full"
              >
                <HeadlessDialogPanel
                  class="pointer-events-auto w-screen max-w-md"
                >
                  <div
                    class="flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl"
                  >
                    <div class="px-4 sm:px-6">
                      <div class="flex items-center justify-between">
                        <LanguageSwitcher />

                        <button @click="open = !open">
                          <img
                            src="/images/icons/x.svg"
                            alt="X"
                            class="h-[24px] w-[24px]"
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      class="relative mt-6 flex flex-1 items-center px-4 sm:px-6"
                    >
                      <ul
                        class="flex-1 space-y-4 text-sm text-[#300E65] xs:space-y-6 xs:text-base"
                      >
                        <!-- Home -->
                        <li>
                          <NuxtLink
                            :href="{ name: 'home' }"
                            @click="open = false"
                          >
                            {{ t('navigation.home') }}
                          </NuxtLink>
                        </li>

                        <!-- MQ Scan -->
                        <li>
                          <a
                            href="#"
                            class="flex items-center"
                            @click="scanOpen = !scanOpen"
                          >
                            <span
                              class="flex-1"
                              :class="{ 'font-bold': scanOpen }"
                            >
                              MQ Scan
                            </span>

                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              :class="{ 'rotate-180': scanOpen }"
                            >
                              <path
                                d="M22.6667 13.3334L16.0001 20.0334L9.33342 13.3334L22.6667 13.3334Z"
                                fill="#300E65"
                              />
                            </svg>
                          </a>

                          <ul v-if="scanOpen" class="my-2 ml-6 space-y-3">
                            <li>
                              <NuxtLink
                                :href="{ name: 'scan.prepare' }"
                                @click="open = false"
                              >
                                {{ t('navigation.scan.prepare') }}
                              </NuxtLink>
                            </li>
                            <li>
                              <NuxtLink
                                :href="{ name: 'scan.select' }"
                                @click="open = false"
                              >
                                {{ t('navigation.scan.scan') }}
                              </NuxtLink>
                            </li>
                            <li>
                              <NuxtLink
                                :href="{ name: 'scan.resume' }"
                                @click="open = false"
                              >
                                {{ t('navigation.scan.resume') }}
                              </NuxtLink>
                            </li>
                            <li>
                              <a href="#" @click="onboarding()">
                                {{ t('navigation.scan.onboarding') }}
                              </a>
                            </li>
                          </ul>
                        </li>

                        <!-- MQ Quiz -->
                        <li>
                          <a
                            href="#"
                            class="flex items-center"
                            @click="quizOpen = !quizOpen"
                          >
                            <span
                              class="flex-1"
                              :class="{ 'font-bold': quizOpen }"
                            >
                              {{ t('navigation.quiz.title') }}
                            </span>

                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              :class="{ 'rotate-180': quizOpen }"
                            >
                              <path
                                d="M22.6667 13.3334L16.0001 20.0334L9.33342 13.3334L22.6667 13.3334Z"
                                fill="#300E65"
                              />
                            </svg>
                          </a>

                          <ul v-if="quizOpen" class="my-2 ml-6 space-y-3">
                            <li v-if="!storeApp.quizAsForm">
                              <NuxtLink
                                :href="{ name: 'quiz.prepare' }"
                                @click="open = false"
                              >
                                {{ t('navigation.quiz.prepare') }}
                              </NuxtLink>
                            </li>
                            <li>
                              <NuxtLink
                                :href="{ name: 'quiz.select' }"
                                @click="open = false"
                              >
                                {{
                                  storeApp.quizAsForm
                                    ? t('navigation.quiz.start-form')
                                    : t('navigation.quiz.start-quiz')
                                }}
                              </NuxtLink>
                            </li>
                            <li>
                              <NuxtLink
                                :href="{ name: 'quiz.onboarding' }"
                                @click="open = false"
                              >
                                {{ t('navigation.quiz.onboarding') }}
                              </NuxtLink>
                            </li>
                          </ul>
                        </li>

                        <!-- About us. -->
                        <li>
                          <NuxtLink
                            :href="{ name: 'about' }"
                            @click="open = false"
                          >
                            {{ t('navigation.about-us') }}
                          </NuxtLink>
                        </li>

                        <!-- FAQ -->
                        <li>
                          <NuxtLink
                            :href="{ name: 'faq' }"
                            @click="open = false"
                          >
                            {{ t('navigation.faq') }}
                          </NuxtLink>
                        </li>

                        <li class="spacer py-2"></li>

                        <!-- Settings -->
                        <li>
                          <NuxtLink
                            :href="{ name: 'settings' }"
                            @click="open = false"
                          >
                            {{ t('navigation.settings') }}
                          </NuxtLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </HeadlessDialogPanel>
              </HeadlessTransitionChild>
            </div>
          </div>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </nav>
</template>

<style lang="css" scoped>
a.router-link-exact-active {
  @apply font-medium text-red-500;
}
</style>
